<template>
  <Content :filter="true" :showFilterDefault="true">
    <template v-slot:topbar>
      <div class="flex h-full items-center">
        <router-link to="/orders/create" class="rounded-full w-12 h-12 bg-gray-200 dark:bg-main4 flex justify-center items-center leading-none hover:bg-gray-300 transition duration-300 ease-in-out dark:text-gray-100">
          <PlusIcon class="w-7 h-7"/>
        </router-link>
      </div>
    </template>

    <template v-slot:filter>
      <div class="w-48 mr-3">
        <Select v-model="filter.selectedBranch" :items="mainCustomer.branches" placeholder="Apkalpojošās filiāle" @click="getItems" :clearAble="true" :showPlaceholder="false" />
      </div>
      <div class="w-48 mr-3">
        <Select v-model="filter.selectedStatus" :items="statuses" placeholder="Statuss" @click="getItems" :clearAble="true" :showPlaceholder="false" />
      </div>
    </template>

    <template v-slot:content>
        <template v-if="orders && orders.data.length > 0">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">
              <template v-for="item in orders.data" :key="item.id">
                <OrderListItem :item="item" />
              </template>
            </ul>
          </div>

          <Pagination :meta="orders.meta" :onPageChange="onPageChange" />

        </template>
        <template v-else>
            <p class="py-6 text-center dark:text-gray-300">
              Diemžēl nekas netika atrasts
            </p>
        </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import Search from "@/services/Helpers/search";
import {mapGetters} from "vuex";
import OrderListItem from "@/components/Orders/OrderListItem";
import Pagination from "@/components/Components/Pagination";
import OrderStatuses from "@/services/Helpers/OrderStatuses";

import Select from "@/components/Components/Select"
import {
  PlusIcon,
} from "@heroicons/vue/solid";

export default {
  name: "Orders",
  components: {
    OrderListItem,
    Pagination,
    Select,
    PlusIcon,
  },
  data: () => ({
    showFiltersTab: true,
    filter: {
      selectedStatus: null,
      selectedBranch: null,
    },
    statuses: null,
    queryParams: null,
  }),
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('resetSearch')
  },
  created() {
    this.$store.dispatch("setDataLoading", true)
    this.statuses = OrderStatuses.statuses();
    // Get query params from URL
    this.queryParams = this.$route.query

    // If there are no URL params set default params
    if(Object.keys(this.queryParams).length === 0) {
        this.$router.replace({
            query: {
                managing_branch_id : this.user.customer.branch.id,
                page: Search.getCurrentPage(),
                q: this.search
            }
        })
    }

    // Update component data from query prams
    this.setFiltersFromParams()

    this.searchOrders()
  },
  computed: {
    ...mapGetters({
    orders: 'orders',
    user: 'user',
    formsForDisplay: 'formsForDisplay',
    isLoading: 'loading',
    mainCustomer: 'systemMainCustomer',
    search: 'search',
    })
  },
  watch: {
    search: function () {
      this.searchOrders()
    }
  },
  methods: {
    getItems() {
          this.$router.replace({
            query: {
                managing_branch_id : this.filter.selectedBranch ? this.filter.selectedBranch.id : '',
                status_id: this.filter.selectedStatus ? this.filter.selectedStatus.id : '',
                page: 1,
                q: this.queryParams.q
            }
        })

        this.queryParams = this.$route.query

        this.searchOrders()
    },
    searchOrders: debounce(function() {
      this.$store.dispatch('filterOrders', this.queryParams)
    }, 500),
    onPageChange(page) {
      this.searchOrders()
    },
    setFiltersFromParams(){
        this.filter.selectedBranch = this.mainCustomer.branches.find(branch => {
            return branch.id == this.queryParams.managing_branch_id
        })

        this.filter.selectedStatus = this.statuses.find(status => {
            return status.id == this.queryParams.status_id
        })
    },
    showForm(formName) {
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName) {
      return this.formsForDisplay.includes(formName)
    },
    addOrder() {
      this.$router.push({ name: 'orders.create'})
    },
  }
}
</script>

<style>

</style>