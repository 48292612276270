<template>
  <li>
    <item-card :url="`/orders/${item.id}`" :blank="true" contentClass="grid grid-cols-12">
      <template v-slot:title>
        <template v-if="item.status">
          <Badge :text="item.status.name" :className="`${statusColor(item.status)} mr-2 mb-2 md:mb-0`" />
        </template>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
          {{ item.customer ? item.customer.name : 'Nav norādīts' }}
        </h3>
        <template v-if="item.have_items_for_production">
          <Badge text="R" className="bg-blue-100 text-blue-800 mr-2 mb-2 md:mb-0" />
        </template>
        <template v-if="item.have_items_for_procurement">
          <Badge text="S" className="bg-red-100 text-red-800 mr-2 mb-2 md:mb-0" />
        </template>
      </template>
      <template v-slot:buttons>
        <template v-if="item.delivery_type">
          <Badge :text="item.delivery_type" className="bg-yellow-500 text-yellow-800 mr-2 mb-2 md:mb-0" />
        </template>

      </template>

      <template v-slot:content>

        
        <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
          <ItemText title="#" :text="item.uuid" />
        </div>
        
        <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
          <ItemText title="Datums" :text="item.order_date" />
        </div>
        
        <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
          <ItemText title="Klienta filiāle" :text="item.branch ? item.branch.name : 'Nav norādīts'" />
        </div>

        <template v-if="item.order_items_count > 0">
          
          <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-1">
            <ItemText title="Pozīcijas" :text="item.order_items_count" />
          </div>
          
          <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-1">
            <ItemText title="Kopā EUR" :text="item.total" />
          </div>
          
          <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-1">
            <ItemText title="PVN EUR" :text="item.vat" />
          </div>
          
          <div class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
            <ItemText title="Summa EUR" :text="item.total_with_vat" />
          </div>
        </template>
      </template>

      <template v-slot:additional v-if="item.notes || item.internal_notes">
        <div class="block border-t border-gray-200 dark:border-gray-700 pt-2">
          <div class="py-1" v-if="item.notes">
            <div class="sm:flex flex-wrap">
              <ItemText title="Piezīmes" :text="item.notes" />
            </div>
          </div>
            <div class="py-1" v-if="item.internal_notes">
                <div class="sm:flex flex-wrap">
                    <ItemText title="Iekšējās piezīmes" :text="item.internal_notes" />
                </div>
            </div>
        </div>
      </template>

    </item-card>
  </li>
</template>

<script>
import Badge from "@/components/Components/Badge"
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Colors from "@/services/Helpers/colors";

export default {
  name: "OrderListItem",
  components: {
    ItemCard,
    ItemText,
    Badge,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  methods: {
    viewOrder(orderId){
      this.$router.push({ path: '/orders/' + orderId })
    },
    statusColor(status) {
      let key = status ? status.id : 0
      return Colors.statusColor(key)
    },
    typeColor(id){
      let key = id - 1
      return Colors.typeColor(key)
    }
  }
}
</script>

<style>

</style>